import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {tableIcons} from "../styles";
import Button from "@material-ui/core/Button";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
});

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function BillingTable(props) {
    const {classes} = props;

    const columns = [
        {
            title: 'Client',
            field: 'client',
        },
        {
            title: 'Date',
            field: 'event_timestamp',
            render: rowData => new Date(rowData["event_timestamp"] * 1000).toLocaleString(),
        },
        {
            title: 'Type',
            field: 'event_type',
        },
        {
            title: 'Description',
            field: 'event_desc',
        },
        {
            title: 'IP',
            field: 'ip',
        },
        {
            title: 'User id',
            field: 'user_id',
        },
    ];
    return (
        <>
            <MaterialTable
                icons={tableIcons}
                isLoading={props.loading}
                title={
                    <div className={classes.tableTitle}>
                        <Typography noWrap className={classes.tableTitleText} variant="h5">
                            Billing events
                        </Typography>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<PictureAsPdfIcon/>}
                            onClick={() => props.onPdf(props.options)}
                            disabled={!props.dataRows || !(props.dataRows && props.dataRows.length)}


                        >
                            PDF
                        </Button>
                    </div>
                }
                columns={columns}
                data={props.dataRows ? props.dataRows : []}
                options={{
                    paging: true,
                    search: true,
                    sorting: true,
                }}
            />
        </>
    );
}

export default withStyles(styles)(BillingTable);
