import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import TimeWindowSelector from '../components/TimeWindowSelectorBilling';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import BillingTable from "../components/BillingTable";
import BillingChart from "../components/BillingChart";
import clsx from "clsx";
import {formatDate} from '../helpers';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 461,
    },
}));

export default function BillingPage() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight2);
    const {setAuthTokens} = useAuth();
    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingChart, setLoadingChart] = useState(false);
    const [events, setEvents] = useState([]);

    const [chartData, setChartData] = useState([]);
    const [options, setOptions] = useState();

    useEffect(() => {
        if (options && options.fromDate && options.interval) {
            setEvents([]);
            setLoadingTable(true);
            flaskAPI.get('/api/billing/events', {
                params: {
                    'date_from': formatDate(options["fromDate"]),
                    'date_till': formatDate(options["tillDate"]),
                    'interval': options["interval"],
                }
            }).then(response => {
                if (response.status === 200) {
                    setEvents(response.data);
                } else {
                    // TODO error message
                }
                setLoadingTable(false);
            }).catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
                setLoadingTable(false);
            });
        }
    }, [options, setAuthTokens]);

    useEffect(() => {
        if (options && options.fromDate && options.interval) {
            setChartData([]);
            setLoadingChart(true);

            flaskAPI.get('/api/billing/chart', {
                params: {
                    'date_from': formatDate(options["fromDate"]),
                    'date_till': formatDate(options["tillDate"]),
                    'interval': options["interval"],
                }
            }).then(response => {
                setChartData(response.data);
                setLoadingChart(false);
            })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                    setLoadingChart(false);
                });
        }
    }, [options, setAuthTokens]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <Grid container direction="column" spacing={3} justify="center">
                    {/* Time Window */}
                    <Grid item>
                        <Paper className={classes.paper}>
                            <TimeWindowSelector onSubmit={(options) => {
                                setOptions(options);
                            }
                            }/>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            {/* Chart */}
            <Grid item xs={9}>
                <Paper className={fixedHeightPaper}>
                    <BillingChart data={chartData} loading={loadingChart}/>
                </Paper>
            </Grid>
            {/* Table */}
            <Grid item xs={12}>
                <BillingTable loading={loadingTable} dataRows={events}
                              options={options}
                              onPdf={(options) => {
                                  if (options && options.fromDate && options.tillDate) {
                                      flaskAPI.get('/api/billing/report/' + formatDate(options.fromDate) + '/' + formatDate(options.tillDate))
                                          .then((response) => {

                                                  const file = new Blob([response.data], {type: "application/pdf"});
                                                  //Build a URL from the file
                                                  const fileURL = URL.createObjectURL(file);
                                                  //Open the URL on new Window
                                                  const pdfWindow = window.open();
                                                  pdfWindow.location.href = fileURL;
                                              }

                                          )
                                          .catch(error => {
                                              if (error && error.response && error.response.status === 401) {
                                                  setAuthTokens();
                                              }
                                          })
                                  }}}
                />
            </Grid>
        </Grid>
    );
}
